import React, { useState, useEffect, useCallback } from 'react';
import { navigate } from "gatsby";
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';

import useMediaQuery from '@mui/material/useMediaQuery';


import Layout from '../components/layout';
import FileUploadButton from '../components/FileUploadButton';
import {gFileInUploadProcess} from '../components/FileUploadButton';
import {DoWeInTheMiddleOfFileUpload} from '../components/FileUploadButton';

import ContantTable from '../components/ContantTable';
import isObjectValid from "../utils/isObjectValid";
import axios from "../projectAxios";

import Stack from '@mui/material/Stack';

import { ClearToken, ClearTokenAndCache, IsTokenExpired } from "../store/store";

import { VideoStorageAndUsage } from "../services/file_service/entities/VideoStorageAndUsage";
import {IFetcherFramework} from "../services/file_service/entities/IFetcherFramework";
import {videoStorageAndUSageFramework} from "../services/file_service/frameworks/videoStorageAndUSageFramework";

import { IDataManipulatorFramework } from "../services/file_service/entities/IDataManipulatorFramework";
import { AddRemoveAndChangeVideoStatusUseCase } from "../services/file_service/use_cases/AddRemoveAndChangeVideoStatusUseCase";

import { OnFileUploadHandler } from "../PageUtilities/OnFileUpload";
import LoadingCircle from "../components/dashboardComponents/LoadingCircle";
import { IsServerMetricsCapable } from "../utils/capabilities";


const Fetcher = (location, dispatcher, token, saveStateDispatcher, cbForLoadingStateChange) => 
{
    // here is the exius req.
    axios.get(
      location,
      {
        headers: {
          Authenticate: token,
        },
      },
      {}
    )
    .then(resp => {
      dispatcher(saveStateDispatcher(resp.data));
      cbForLoadingStateChange(false);
      return;
    })
    .catch(err => {
      if ( err.response && (err.response.status === 403 || err.response.status === 401)) {     
        
        // only if not process at the moment sometihng
        if (gFileInUploadProcess !== null && gFileInUploadProcess !== undefined && gFileInUploadProcess > 0)
        {
          console.log("Do nothing");
          return null;
        }

        // Token probebly expired
        dispatcher(ClearToken());
        navigate("/login");
        return null;
      }

      if ( err.response && err.response.status === 404) {          
        // We probebly just don't have the information from some reason
        cbForLoadingStateChange(false);
        return null;
      }
    });
}

const Deleter = (dispatcher, token, movieId, cbForChangeLocalState) => 
{
    if (movieId === null || movieId === undefined)
    {
      // We can't delete an unknown file
      if (gFileInUploadProcess !== null && gFileInUploadProcess !== undefined && gFileInUploadProcess > 0)
      {
        return Promise.reject("Movie ID is required for deletion.");
      }

      // If we can - something is mestup here - we prefer to do a normal clear to all the cache and go again to login
      // In the login the user will get a clean and fresh start of his status based on the server status
      dispatcher(ClearTokenAndCache());
      navigate("/login");
      return null;
    }

    // here is the exius req.
    const data = new FormData();
    data.append('movieId', movieId)
    return axios.post("/MovieUpload/Delete", data, { headers: {Authenticate: token}})
    .then(resp => {
      return;
    })
    .catch(err => {
      if ( err.response && (err.response.status === 403 || err.response.status === 401)) {
        // only if not process at the moment sometihng
        if (gFileInUploadProcess !== null && gFileInUploadProcess !== undefined && gFileInUploadProcess > 0)
        {
          console.log("Do nothing");
          return null;
        }

        // Token probebly expired
        dispatcher(ClearToken());
        navigate("/login");
        return null;
      }
      
      if ( err.response && err.response.status === 400) {          
        // We probebly just don't have the information from some reason
        cbForChangeLocalState();
        return null;
      }
    });
}

const getFirstName = (userInformation) => {
  if (isObjectValid(userInformation) !== true)
  {
    return null;
  }

  return JSON.parse(userInformation.data).firstName;
}

const getEncryptionStatus = (userInformation) =>
{
  if (isObjectValid(userInformation) !== true)
  {
    return null;
  }

  const encStatus = JSON.parse(userInformation.data).encryption;
  return encStatus === true || encStatus === "true" || encStatus === "True";
}

const getIsServerMetricsCapable = (userInformation) => {
  if (isObjectValid(userInformation) !== true)
  {
    return null;
  }

  return IsServerMetricsCapable(JSON.parse(userInformation.data).capabilities);
}

var shouldRerenderContant = false;

const Content = () => {
  const dispatch = useDispatch();
  const isBigScreen = useMediaQuery('(min-width:680px)');
  const { state: isLoggedIn, token, userInformation, videoUsageStorage } = useSelector((state) => ({
    isLoggedIn: state?.isLoggedIn || false,
    token: state?.token || null,
    userInformation: state?.userInformation || null,
    videoUsageStorage: state?.videoUsageStorage || null,
  }));

  const [fileNeedsToBeUploaded, setFileNeedsToBeUploaded] = useState(null);
  const [isUserInformationLoading, setIsUserInformationLoading] = useState(true);
  const [isLoadingSomeOperation, setIsLoadingSomeOperation] = useState(false);


  

  useEffect(() => {
    //console.log("[Content page - useEffect] start");
    if (isLoggedIn === false || token === null || token === undefined)
    {
       // only if not process at the moment sometihng
       if (gFileInUploadProcess !== null && gFileInUploadProcess !== undefined && gFileInUploadProcess > 0)
       {
         console.log("Do nothing");
         return;
       }

      navigate("/login");
    }

    const helper = new videoStorageAndUSageFramework(token);
    const usageAndStorageFramework = new IFetcherFramework(helper);

    // Move it above - is it going to change anything?
    const dataManipulator = new IDataManipulatorFramework(helper);
    new AddRemoveAndChangeVideoStatusUseCase(dataManipulator); // This will save itself

    // this will update the state on finish if there is what to update
    if (videoUsageStorage === null || videoUsageStorage === undefined)
    {
      usageAndStorageFramework.FetchFromServer(dispatch).then( resp => { setIsUserInformationLoading(false); });
    }
    else
    {
      setIsUserInformationLoading(false);
    }


    // Here we take care the actual upload of staff
    //console.log("fileNeedsToBeUploaded:", fileNeedsToBeUploaded !== null, shouldRerenderContant);
    if (shouldRerenderContant === true && fileNeedsToBeUploaded !== null)
    {
      // we start working - no need to rework

      shouldRerenderContant = false;
      // When this finish to run - he will update our state to null
      setFileNeedsToBeUploaded(null);
      OnFileUploadHandler(isUserInformationLoading, fileNeedsToBeUploaded, videoUsageStorage , dispatch, null);
    }

    //console.log("[Content page - useEffect] finish");
  }, [isLoggedIn,  dispatch, token,  videoUsageStorage, fileNeedsToBeUploaded]);


  useEffect(() => {
    const interval = setInterval(() => {
             // only if not process at the moment sometihng
        if (gFileInUploadProcess !== null && gFileInUploadProcess !== undefined && gFileInUploadProcess > 0)
        {
          console.log("Do nothing");
          return;
        }

      dispatch(IsTokenExpired()); // Dispatch the action to update the state
    }, 60000); // Check every 1 minute (60,000 milliseconds)

    return () => {
      clearInterval(interval); // Clear the interval when the component unmounts
    };
  }, [dispatch]);


  // Use this effect for make sure during upload the user can't switch screen
  /*useEffect(() => {
    const handleBeforeUnload = (e) => {
      // Check if we during any upload
      if (false === DoWeInTheMiddleOfFileUpload())
      {
        return;
      }

      // Cancel the event
      e.preventDefault();

      // Chrome requires returnValue to be set
      e.returnValue = '';

      // Prompt the user with a confirmation dialog
      const confirmationMessage = 'Are you sure you want to leave?';
      e.returnValue = confirmationMessage; // For legacy browsers

      return confirmationMessage;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      // Clean up by removing the event listener when the component unmounts
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);*/



  const onNotAuthorizedFileUpload = () =>
  {
           // only if not process at the moment sometihng
           if (gFileInUploadProcess !== null && gFileInUploadProcess !== undefined && gFileInUploadProcess > 0)
           {
             console.log("Do nothing");
             return;
           }

    dispatch(ClearToken());
    navigate("/login");
  }


  function extractMovieId(link) {
    if (link.startsWith("http://") || link.startsWith("https://"))
    {
      return link.split("/")[4];
    }
   return link.split("/")[2];
  }

  const changeStatusOfASingleVideo = (link, status) =>
  {
    if (videoUsageStorage === null || videoUsageStorage === undefined)
    {
      // It's not possible to delete something if we have an empty list
      return;
    }

    if (videoUsageStorage.VideoList.length === 0)
    {
      // It's not possible to delete something if we have an empty list
      return;
    }

    const addRemChange = new AddRemoveAndChangeVideoStatusUseCase(null);
    // Get the proper file
    var ourOBj = null;
    videoUsageStorage.VideoList.forEach(element => {
      if (ourOBj !== null)
      {
        return;
      }

      if (element.MasterLink === link)
      {
        ourOBj = JSON.parse(JSON.stringify(element));
        return;
      }
    });

    if (ourOBj === null)
    {
      return;
    }
    
    ourOBj.Status = status;
    addRemChange.ChangeStatusOfVideo(ourOBj, videoUsageStorage, dispatch);
  }

  const onFIleDeleteHandler =(raws, selected) => {
    if (raws.length === 0 || selected.length === 0)
    {
      return;
    }

    if (videoUsageStorage === null || videoUsageStorage === undefined)
    {
      // It's not possible to delete something if we have an empty list
      return;
    }

    if (videoUsageStorage.VideoList.length === 0)
    {
      // It's not possible to delete something if we have an empty list
      return;
    }

    setIsLoadingSomeOperation(true);
    //console.log("[onFIleDeleteHandler] set setIsLoadingSomeOperation(true)");

    let successfullyDeleted = [];
    const requests = [];
    for (let i = 0; i < raws.length; i++) {
      if (selected.includes(raws[i].lineId)) {

        // Found send delete request - dispatch delete request
        successfullyDeleted.push(new VideoStorageAndUsage(extractMovieId(raws[i].link), 0,0,0,"","",22,0,0,0,0));
        requests.push(Deleter(dispatch, token, extractMovieId(raws[i].link), () => changeStatusOfASingleVideo(raws[i].link, 23)));
        //Deleter(dispatch, token, extractMovieId(raws[i].link), () => changeStatusOfASingleVideo(raws[i].link, 23)); //23 => ErrorDelete
      }
    }

    Promise.all(requests)
    .then((respList) => {
      // All whare executed

      // change the local status of videoDataObj
    if (successfullyDeleted.length === 0)
    {
      return;
    }

    // Send list to handler
    const addRemChange = new AddRemoveAndChangeVideoStatusUseCase(null);
    addRemChange.RemoveVideoList(successfullyDeleted, videoUsageStorage, dispatch);

    // Set it to finish
    setIsLoadingSomeOperation(false);
    //console.log("[onFIleDeleteHandler] set setIsLoadingSomeOperation(false)");
    })
    .catch(error => {
      //console.log(error);

          // Set it to finish
    setIsLoadingSomeOperation(false);
    //console.log("[onFIleDeleteHandler] set setIsLoadingSomeOperation(false)");
    });
  }

  

  const LocalFunctionThatCallTheState =  useCallback((files) =>
  {
    //console.log("[LocalFunctionThatCallTheState] start");
    shouldRerenderContant = true;
    setFileNeedsToBeUploaded(files);
    //console.log("[LocalFunctionThatCallTheState] finish");
  }, []);

  const CallBack2Memoisez = useCallback(() => {
    onNotAuthorizedFileUpload();
  }, []);

  return (
    <>
      <Helmet>
          <title>Content</title>
      </Helmet>

      <Layout userName={getFirstName(userInformation)} canReadServerMetrics={getIsServerMetricsCapable(userInformation)}>
        <Stack spacing={3} style={isBigScreen === false ? {height: "700vh" /*This is a fix for the small screen devices will still see the background*/} : null}>
          <FileUploadButton
            token={token}
            onLoadHandler={LocalFunctionThatCallTheState}
            onNotAuthorized={CallBack2Memoisez}
            shouldBeEncrypted={getEncryptionStatus(userInformation)}/>


          { isLoadingSomeOperation === true ? <LoadingCircle /> : 
            <ContantTable 
              videoUsageStorage={videoUsageStorage}
              isLoading={isUserInformationLoading === true}
              onFIleDeleteHandler={onFIleDeleteHandler}/> }
        </Stack>
      </Layout>
    </>
  )
}


export default Content;
