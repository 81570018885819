import React, { useState } from 'react'

import NoAvailableData from './NoAvailableData';
import LoadingCircle from './dashboardComponents/LoadingCircle';
import SortableTableWrapper from './SortableTable';
import { GetEbeddedCodeLink } from '../utils/embeddedCode';
import DialogBoxEmbeddedLink from './DialogBoxEmbeddedLink';
import { GetUsageAndStorageSortedUseCase } from "../services/file_service/use_cases/GetUsageAndStorageSortedUseCase";

import "../global.css";

var getUsageAndStorageSortedUseCase = new GetUsageAndStorageSortedUseCase();

const IsUndefOrNull = (obj) =>
{
    if (obj === null)
    {
        return true;
    }

    return obj === undefined;
}


function createData(lineId, status, fileName, size, uploadDate, monthlyBandwidth, link, TotalViews, ViewsPrecent) {
    return { lineId, status, fileName, size, uploadDate, monthlyBandwidth, link, TotalViews,  ViewsPrecent};
}


const GetNumberedListFromObject = (uniteObject) =>
{
    if (IsUndefOrNull(uniteObject))
    {
        return [];
    }

    const lines = [];
    var lineId = 0;
    uniteObject.forEach(obj => {
        const tableLine = createData(lineId, obj.Status, obj.FileName, obj.Size, obj.UploadDate, obj.TotalUsage, obj.MasterLink, obj.TotalViews, obj.ViewsPrecent);
        lineId += 1;
        lines.push(tableLine);
    });

    return lines;
}

const ContantTable = ({videoUsageStorage, isLoading, onFIleDeleteHandler}) => {
    const [embeddedCode, setEmbeddedCode] = useState(null);
    const [showEmbeddedCode, setShowEmbeddedCode] = useState(false);
    const [showClipboardNotice, setShowClipboardNotice] = useState(false);

    const ShowEmbeddedLink = (link) =>
    {
        const localEmbeddedCode = GetEbeddedCodeLink(link);
        setEmbeddedCode(localEmbeddedCode);

        // copy code to clipboard
        navigator.clipboard.writeText(localEmbeddedCode);

        setShowEmbeddedCode(true);
        setShowClipboardNotice(true);
    }

    const closeDialog = () =>
    {
        setShowEmbeddedCode(false);
        setShowClipboardNotice(false);
    }

    const rowWasClicked = (link, status) =>
    {
        if (parseInt(status, 10) === 1)
        {
            ShowEmbeddedLink(link);
        }
    }

    // Set the finel view
    let sortableTableView = <NoAvailableData extraInformation={"You will be able to access this screen once you have uploaded your first video"}/>;
    const tableLines = IsUndefOrNull(videoUsageStorage) ? [] : GetNumberedListFromObject(getUsageAndStorageSortedUseCase.GetSortedByUploadDate(videoUsageStorage));
    if (tableLines.length !== 0)
    {
        sortableTableView = (<>
        <DialogBoxEmbeddedLink
            embeddedCode={embeddedCode}
            showDialog={showEmbeddedCode}
            dialogClose={closeDialog} 
            showSnackbar={showClipboardNotice}
            snackbarClose={() => setShowClipboardNotice(false)}
        />
        
        <SortableTableWrapper 
            rows={tableLines}
            onRowClick={rowWasClicked}
            onFIleDeleteHandler={onFIleDeleteHandler}/>
                            </>);
    }


    let view = isLoading === true ? <LoadingCircle/> : sortableTableView;

  return (<>
        {view}
    </>
  )
}

export default ContantTable;
