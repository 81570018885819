import React from 'react'
import {  Dialog, DialogTitle, Snackbar, Typography } from '@mui/material';


const GetDialogBox = (isThisNeedToBeShown, oncloseCallback, embeddedCode) =>
{
    return <Dialog onClose={oncloseCallback} open={isThisNeedToBeShown}>
                <DialogTitle>Embedded Code</DialogTitle>
                <Typography variant="body2" style={{padding:30}}>
                    {embeddedCode}
                </Typography>
            </Dialog>;
}

const GetNotification = (isThisNeedToBeShown, oncloseCallback) =>
{
    return <Snackbar
                open={isThisNeedToBeShown}
                onClose={oncloseCallback}
                autoHideDuration={4000}
                message="The code has been copied to the clipboard"
            />;
}


const DialogBoxEmbeddedLink = ({embeddedCode, showDialog, dialogClose, showSnackbar, snackbarClose}) => {
    return (<>
        {GetDialogBox(showDialog, dialogClose, embeddedCode)}
        {GetNotification(showSnackbar, snackbarClose)}
    </>);
}

export default DialogBoxEmbeddedLink;